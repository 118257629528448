"use strict!";

function handlemenutype(e) {
    $(".pcoded").attr("nav-type", e)
}

$(document).ready(function () {
    function e(e, t, a) {
        var o = new Date;
        o.setTime(o.getTime() + 24 * a * 60 * 60 * 1e3);
        var n = "expires=" + o.toGMTString();
        document.cookie = e + "=" + t + ";" + n + ";path=/"
    }

    function t(e) {
        for (var t = e + "=", o = decodeURIComponent(document.cookie), n = o.split(";"), r = 0; r < n.length; r++) {
            for (var i = n[r]; " " == i.charAt(0);) i = i.substring(a);
            if (0 == i.indexOf(t)) return i.substring(t.length, i.length)
        }
        return ""
    }

    var a = 1, o = "themelight1", n = "theme1", r = "theme1", i = "theme1", d = "theme1", c = "theme", h = "light",
        m = "wide", l = "shrink", p = "st2";
    !function () {
        o = "" != t("NavbarBackground") ? t("NavbarBackground") : o, n = "" != t("header-theme") ? t("header-theme") : n, r = "" != t("menu-title-theme") ? t("menu-title-theme") : r, i = "" != t("themebg-pattern") ? t("themebg-pattern") : i, d = "" != t("active-item-theme") ? t("active-item-theme") : d, c = "" != t("fream-type") ? t("fream-type") : c, h = "" != t("layoutlayout") ? t("layoutlayout") : h, m = "" != t("vertical-layout") ? t("vertical-layout") : m, l = "" != t("vertical-effect") ? t("vertical-effect") : l, p = "" != t("menu-icon-style") ? t("menu-icon-style") : p
    }(), $(".pcoded-navbar .pcoded-hasmenu").attr("subitem-icon", "style1"), $("#pcoded").pcodedmenu({
        themelayout: "horizontal",
        horizontalMenuplacement: "top",
        horizontalBrandItem: !0,
        horizontalLeftNavItem: !0,
        horizontalRightItem: !0,
        horizontalSearchItem: !0,
        horizontalMobileMenu: !0,
        MenuTrigger: "hover",
        SubMenuTrigger: "hover",
        activeMenuClass: "active",
        ThemeBackgroundPattern: i,
        HeaderBackground: n,
        LHeaderBackground: r,
        NavbarBackground: o,
        ActiveItemBackground: d,
        SubItemBackground: "theme2",
        menutype: p,
        freamtype: c,
        layouttype: h,
        ActiveItemStyle: "style1",
        ItemBorder: !0,
        ItemBorderStyle: "none",
        SubItemBorder: !0,
        DropDownIconStyle: "style1",
        FixedNavbarPosition: !1,
        FixedHeaderPosition: !1,
        horizontalNavIsCentered: !1,
        horizontalstickynavigation: !1,
        horizontalNavigationMenuIcon: !0
    }), function () {
        $(".theme-color > a.Layout-type").on("click", function () {
            var t = $(this).attr("layout-type");
            $(".pcoded").attr("layout-type", t), e("layoutlayout", t, a), "dark" == t && ($(".pcoded-header").attr("header-theme", "theme1"), $(".pcoded-navbar").attr("navbar-theme", "theme1"), $(".pcoded-navbar").attr("active-item-theme", "theme1"), $(".pcoded").attr("fream-type", ""), $("body").addClass("dark"), $("body").attr("themebg-pattern", "theme1"), $(".pcoded-navigation-label").attr("menu-title-theme", "theme9"), e("header-theme", "theme1", a), e("NavbarBackground", "theme1", a), e("menu-title-theme", "theme1", a), e("themebg-pattern", "theme1", a), e("fream-type", "", a), e("active-item-theme", "theme1", a)), "light" == t && ($(".pcoded-header").attr("header-theme", "theme1"), $(".pcoded-navbar").attr("navbar-theme", "themelight1"), $(".pcoded-navigation-label").attr("menu-title-theme", "theme1"), $(".pcoded-navbar").attr("active-item-theme", "theme1"), $(".pcoded").attr("fream-type", ""), $("body").removeClass("dark"), $("body").attr("themebg-pattern", "theme1"), e("header-theme", "theme1", a), e("NavbarBackground", "themelight1", a), e("menu-title-theme", "theme1", a), e("themebg-pattern", "theme1", a), e("fream-type", "", a), e("active-item-theme", "theme1", a)), "reset" == t && (e("NavbarBackground", null, 0), e("header-theme", null, 0), e("menu-title-theme", null, 0), e("themebg-pattern", null, 0), e("active-item-theme", null, 0), e("fream-type", null, 0), e("layoutlayout", null, 0), e("vertical-layout", null, 0), e("vertical-effect", null, 0), location.reload())
        })
    }(), function () {
        $(".theme-color > a.leftheader-theme").on("click", function () {
            var t = $(this).attr("menu-caption");
            $(".pcoded-navigation-label").attr("menu-title-theme", t), e("menu-title-theme", t, a)
        })
    }(), function () {
        $(".theme-color > a.header-theme").on("click", function () {
            var t = $(this).attr("header-theme"), o = $(this).attr("active-item-color");
            $(".pcoded-header").attr("header-theme", t), $(".pcoded-navbar").attr("active-item-theme", o), $(".pcoded").attr("fream-type", t), $(".pcoded-navigation-label").attr("menu-title-theme", t), $("body").attr("themebg-pattern", t), e("header-theme", t, a), e("active-item-theme", o, a), e("menu-title-theme", t, a), e("themebg-pattern", t, a), e("fream-type", t, a)
        })
    }(), function () {
        $(".theme-color > a.navbar-theme").on("click", function () {
            var t = $(this).attr("navbar-theme");
            $(".pcoded-navbar").attr("navbar-theme", t), e("NavbarBackground", t, a), "themelight1" == t && ($(".pcoded-navigation-label").attr("menu-title-theme", "theme1"), e("menu-title-theme", "theme1", a)), "theme1" == t && ($(".pcoded-navigation-label").attr("menu-title-theme", "theme9"), e("menu-title-theme", "theme9", a))
        })
    }(), function () {
        $(".theme-color > a.active-item-theme").on("click", function () {
            var t = $(this).attr("active-item-theme");
            $(".pcoded-navbar").attr("active-item-theme", t), e("active-item-theme", t, a)
        })
    }(), function () {
        $(".theme-color > a.themebg-pattern").on("click", function () {
            var t = $(this).attr("themebg-pattern");
            $("body").attr("themebg-pattern", t), e("themebg-pattern", t, a)
        })
    }(), function () {
        $("#theme-layout").change(function () {
            $(this).is(":checked") ? ($(".pcoded").attr("vertical-layout", "box"), e("vertical-layout", "box", a), $("#bg-pattern-visiblity").removeClass("d-none")) : ($(".pcoded").attr("vertical-layout", "wide"), e("vertical-layout", "wide", a), $("#bg-pattern-visiblity").addClass("d-none"))
        })
    }(), function () {
        $("#vertical-menu-effect").val("shrink").on("change", function (t) {
            t = $(this).val(), $(".pcoded").attr("vertical-effect", t), e("vertical-effect", t, a)
        })
    }(), function () {
        $("#vertical-border-style").val("solid").on("change", function (e) {
            e = $(this).val(), $(".pcoded-navbar .pcoded-item").attr("item-border-style", e)
        })
    }(), function () {
        $("#vertical-dropdown-icon").val("style1").on("change", function (e) {
            e = $(this).val(), $(".pcoded-navbar .pcoded-hasmenu").attr("dropdown-icon", e)
        })
    }(), function () {
        $("#vertical-subitem-icon").val("style5").on("change", function (e) {
            e = $(this).val(), $(".pcoded-navbar .pcoded-hasmenu").attr("subitem-icon", e)
        })
    }(), function () {
        $("#sidebar-position").change(function () {
            $(this).is(":checked") ? ($(".pcoded-navbar").attr("pcoded-navbar-position", "fixed"), $(".pcoded-header .pcoded-left-header").attr("pcoded-lheader-position", "fixed")) : ($(".pcoded-navbar").attr("pcoded-navbar-position", "absolute"), $(".pcoded-header .pcoded-left-header").attr("pcoded-lheader-position", "relative"))
        })
    }(), function () {
        $("#header-position").change(function () {
            $(this).is(":checked") ? ($(".pcoded-header").attr("pcoded-header-position", "fixed"), $(".pcoded-navbar").attr("pcoded-header-position", "fixed"), $(".pcoded-main-container").css("margin-top", $(".pcoded-header").outerHeight())) : ($(".pcoded-header").attr("pcoded-header-position", "relative"), $(".pcoded-navbar").attr("pcoded-header-position", "relative"), $(".pcoded-main-container").css("margin-top", "0px"))
        })
    }(), function () {
        $("#collapse-left-header").change(function () {
            $(this).is(":checked") ? ($(".pcoded-header, .pcoded ").removeClass("iscollapsed"), $(".pcoded-header, .pcoded").addClass("nocollapsed")) : ($(".pcoded-header, .pcoded").addClass("iscollapsed"), $(".pcoded-header, .pcoded").removeClass("nocollapsed"))
        })
    }()
}), handlemenutype("st2");
